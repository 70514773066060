import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Api from '../Components/Services/ApiCall'
import '../App.css'
import "../Css/Signin.css"
import { Images } from '../Images/Index';
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import {
    validPhoneNumberRegex,
    validOnlyEmailRegex
} from '../Components/CommanRules';
import JobseekerIndex from './JobseekerSignup/JobseekerIndex';
import SigninBase from './SigninBase';
import EmployerIndex from './EmployerSignup/EmployerIndex';

export default function Onlineemployersignup() {

    const location = useLocation();
    const { ID, Flag, UserT, Page } = location.state;

    useEffect(() => {
        set_Usertype(UserT)
        // GetMenu(0)
        // GetQualification()
        GetUserType()
        // bindStaffGradeDdl(0)
        //  getEmployeeCode()
        //  GetPlandetail()
        //  GetAmount()
        //   GetDepartment()
        GetCountryList()
        GetCityList()
        if (ID != 0) {



        }
        else {

            //   GetDepartment()

        }
        set_Usertype("JobSeeker")
    }, []);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [phone, setPhonenum] = useState("");
    const [pannumber, setPannum] = useState("");
    const [aadharnumber, setAadharno] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [usertype, setUsertype] = useState("");
    const [department, setDepartment] = useState([]);
    const [selectdepartment, setselectDepartment] = useState("");


    const [selectedUsertype, setselectedUsertype] = useState([]);
    const [selectedGradeType, setselectedGradeType] = useState("");
    const [selectedGrade, setselectedGrade] = useState("");
    const [user, setUser] = useState("");
    const [address, setAddress] = useState("");
    const [grade, setGrade] = useState("");
    const [designation, setDesignation] = useState("");


    const [agentcode, setAgentcode] = useState("");

    const [officecode, setOfficecode] = useState("");
    const [_Usertype, set_Usertype] = useState("");

    const [ListMenu, setListMenu] = useState([]);
    const [reportempcode, setReportempcode] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [pincode, setPincode] = useState("")
    const [acholder, setAcholder] = useState("")
    const [accountno, setAccountno] = useState("")
    const [ifsccode, setIfsccode] = useState("")
    const [branchname, setBranchname] = useState("")
    const [bankname, setbankname] = useState("")
    const [reportingempcode, setreportingempcode] = useState("")
    const [accountholdername, seaccountholdername] = useState("")
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [gradetype, setgradetype] = useState([]);
    const [staffGrade, setStaffGrade] = useState([]);
    const [selectedStaffGrade, setSelectedStaffGrade] = useState([]);
    const [staffGradeType, setStaffGradeType] = useState([]);
    const [staffDesignation, setStaffDesignation] = useState("");

    const [selectedPlan, setselectedPlan] = useState("");
    const [plan, setPlan] = useState("");
    const [amount, setAmount] = useState([]);
    const [employeebranch, setEmployeebranch] = useState("");
    const [incharge, setIncharge] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [companynumber, setCompanynumber] = useState("");
    const [companyaddress, setCompanyaddress] = useState("");
    const [companygst, setCompanygst] = useState("");

    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);


    const [ListemployeeGrade, setListemployeeGrade] = useState([]);
    const [ListemployeeCode, setListemployeeCode] = useState([]);
    const [ListemployeeDesignation, setListemployeeDesignation] = useState("");

    const [SelectOfficegrade, setSelectOfficegrade] = useState("");
    const [SelectOFficeEmpCode, setSelectOFficeEmpCode] = useState("");
    const [SelectOFficeDesignation, setSelectOFficeDesignation] = useState("");
    const [officeinchargename, setOfficeinchargename] = useState("");
    const [dob, setDob] = useState("");
    const [uploadFile, setuploadFile] = useState("");
    const [mobilenumber, setMobilenumber] = useState("");
    const [filename, setfilename] = useState("");
    const [formFields12, setFormFields] = useState([
        { companyname: '', designation: '', years: '', month: '' },
    ])

    const [formFields, setFormField] = useState([
        { qualify: '', course: '', institution: '', passedout: '' },
    ])




    const handleFormChange1 = (event, index, F) => {

        let data = [...formFields];
        if (F == 1) {
            data[index].qualify = event.target.value;
        } if (F == 2) {
            data[index].course = event.target.value;
        } if (F == 3) {
            data[index].institution = event.target.value;
        } if (F == 4) {
            data[index].passedout = event.target.value;
        }
        setFormField(data);
    }

    const uploadImage = async (e) => {
        const file = e.target.files[0];

        var filename = file.name;
        setfilename(filename)

        const base64 = await convertBase64(file);
        setuploadFile(base64);
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }


    const submit = (e) => {
        e.preventDefault();
    }

    const addFields = () => {
        let object = { companyname: '', designation: '', years: '', month: '' }

        setFormFields([...formFields12, object])
    }



    const removeFields = (index) => {
        let data = [...formFields12];
        data.splice(index, 1)
        setFormFields(data)
    }

    const addFields1 = () => {
        let object = { qualify: '', course: '', institution: '', passedout: '' }

        setFormField([...formFields, object])
    }
    const removeFields1 = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormField(data)
    }
    const handleFormChange = (event, index, F) => {

        let data = [...formFields12];
        if (F == 1) {
            data[index].companyname = event.target.value;
        } if (F == 2) {
            data[index].designation = event.target.value;
        } if (F == 3) {
            data[index].years = event.target.value;
        } if (F == 4) {
            data[index].month = event.target.value;
        }
        setFormFields(data);
    }




    //Jobseeker


    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        // let _response = await Api.DirectApiCall('city', country, value)
        // setCityList(_response)
        var url = "/DropDown/GetCities";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCityList(_response)
    }



    async function getSelectedStaffGradeTypeDdl(e) {

        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function getEmployeeCode() {


        var url = "/DropDown/GetOfficetypecode";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setListemployeeCode(_response)

    }

    async function GetUserType() {

        var url = "/DropDown/GetRole?code=All";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setUsertype(_response)

    }
    async function getSelectedStaffGradeDdl(e) {

        setGrade(e.target.value);
        setSelectedStaffGrade(e.target.value);
        var staffGrade = e.target.options[e.target.selectedIndex].text;
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == e.target.value;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]

        setStaffGradeType(sGrade);
    }
    async function GetStaffGrademannual(id) {

        try {
            // debugger;
            var Param = { mststaffgradedesignationid: 0 }
            var url = "/StaffGradeType/BindStaffGradeType";
            let _response121 = await Api.BindGrid(Param, url);

            _response121 = _response121.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response121.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }
    async function GetStaffGradeTypemannual(id) {
        // debugger;  
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response1 = await Api.BindGrid(Param, url)
        _response1 = _response1.filter(function (el) {
            return el.Staffgradetypecode == id;
        });
        var sGrade = [...new Set(_response1.map(q => {
            var x = {
                Automaticbaseedongradetype: q.Automaticbaseedongradetype,
                Mststaffgradetypeid: q.Mststaffgradetypeid
            }
            return x;
        }))]

        setStaffGradeType(sGrade);
    }

    async function GetPlandetail() {

        var url = "/DropDown/GetEmployerdetail";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response)


    }



    async function GetAmount(e) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetEmployeramount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
    }



    async function GetQualification() {

        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)

    }
    async function GetMenu(id) {
        var Param = { mstmenuid: id }
        var url = "/Menu/BindMenu";
        let _response = await Api.BindGrid(Param, url)



        setListMenu(_response);


    }
    async function GetRoleType(id) {
        var Param = { Mstroleid: id }
        var url = "/Role/BindRole";
        let _response1 = await Api.BindGrid(Param, url);
        setselectedUsertype(id)
        setListMenu(JSON.parse(_response1[0].RoleList));

        await bindStaffGradeDdl(id)

    }

    async function GetDepartment() {

        var url = "/DropDown/GetDepartment";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDepartment(_response)
    }

    async function GetGradeType(id) {
        setselectedGrade(id)
        var url = "/DropDown/GetGradeType?GradeCode=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        try {

            setgradetype(_response[0].Text.split(","))
        }
        catch { }

    }

    async function bindStaffGradeDdl(id) {
        var Param = { mststaffgradedesignationid: 0 }
        var url = "/StaffGradeType/BindStaffGradeType";
        try {

            let _response1 = await Api.BindGrid(Param, url);

            _response1 = _response1.filter(function (el) {
                return el.Role == id;
            });
            var sGrade = [...new Set(_response1.map(q => q.Staffgradetypecode))]
            setStaffGrade(sGrade);
        } catch {
        }
    }

    async function GetGradeTypesManual(ID) {
        var Param = { Staffgradetypecode: ID }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url)
        setStaffGradeType(_response);
    }
    async function getSelectedStaffGradeTypeDdl(e) {

        setselectedGradeType(e.target.value);
        var Param = { Mststaffgradetypeid: e.target.value }
        var url = "/StaffGradeType/BindStaffGradeType";
        let _response = await Api.BindGrid(Param, url);
        setStaffDesignation(_response[0].Designation);
    }
    async function Getofficemployeename(e) {

        setSelectOFficeEmpCode(e);
        var Param = { Mstofficetypecreationid: e }
        var url = "/DropDown/GetInchargeNmae?id=" + e;
        let _response = await Api.BindGrid(Param, url);
        setOfficeinchargename(_response[0].Text);

    }

    async function IUDOperation(flag, id) {
        var Name = name;
        var Mobile = phone;
        var Pan = pannumber;
        var Qualify = selectedQualiify;
        var User = selectedUsertype;
        var Email = email;
        var Aadhar = aadharnumber;
        var Address = address;
        var State = state;
        var Country = country;
        var City = city;
        var Pincode = pincode;
        var Acholder = acholder;
        var Accountno = accountno;
        var Ifsccode = ifsccode;
        var Branchname = branchname;
        var SelectedStaffGrade = selectedStaffGrade;
        var StaffGradeType = staffGradeType;
        var Department = selectdepartment;
        var Reportempcode = reportingempcode;
        var Bankname = bankname;
        var CompanyName = companyname;
        var Companynumber = companynumber;
        var Companyaddress = companyaddress;
        var CompanyGST = companygst;
        var Usertype = usertype;

        if (Flag != 'D') {


            if ((CompanyName == "")) {

                toast.error('Enter  the Company Name')
                return false;
            }
            if ((Companynumber == "")) {

                toast.error('Enter  the Company Number')
                return false;
            }
            if ((Companyaddress == "")) {

                toast.error('Enter  the Company Address')
                return false;
            }
            if ((Name == "")) {

                toast.error('Enter  the Name')
                return false;
            }

            else {
                if (!(Name.length > 3)) {
                    toast.error('Enter Valid Name')
                    return false;

                }
            }
            // if (Mobile == "") {
            //     toast.error('Enter Mobile Number')
            //     return false;
            // }
            if (Email == "") {
                toast.error('Enter the Email Id')
                return false;
            }


            if (Country == "") {
                toast.error('Please select a Country')
                return false;
            }
            if (State == "") {
                toast.error('Please select a State ')
                return false;
            }
            if (City == "") {
                toast.error('Please select a City')
                return false;
            }
            if (Pincode == "") {
                toast.error('Please enter a Pincode')
                return false;
            }
            else if (!(Pincode.length == 6)) {
                toast.error('Pincode Must 6 Digit')
                return false;

            }
            if (Address == "") {
                toast.error('Please enter a Address')
                return false;
            }

        }

        var Param =
        {
            "mstuserregid": 0,
            "msusertregcode": "",
            "name": Name,
            "mobilenumber": Mobile,
            "pannumber": Pan,
            "qualification": "string",
            "usertype": 0,
            "plandetails": 0,
            "amount": 0,
            "empbranch": "string",
            "incharge": "string",
            "emailid": Email,
            "aadhaarnumber": Aadhar,
            "rolecab": "Employer",
            "agGradetype": "string",
            "agGrade": "string",
            "agDesignation": "string",
            "department": "string",
            "agAgentcode": "string",
            "officecode": "string",
            "reportingempcode": "string",
            "city": City,
            "state": State,
            "country": Country,
            "address": Address,
            "pincode": Pincode,
            "accountholdername": "string",
            "accountnumber": "string",
            "ifsccode": "string",
            "branchname": "string",
            "bankname": "string",
            "password": "string",
            "companyname": CompanyName,
            "companyphoneNo": Companynumber,
            "companyaddress": Companyaddress,
            "companygst": CompanyGST,
            "confirmpassword": "string",
            "rolevscab": "Employer",
            "active": "string",
            "flag": "I",
            "searchField": "Employer",
            "subscribe": "string",
            "plantype": "string",
            "planperiod": "string",
            "activestatus": "string",
            "planamount": 0,


        }

        var url = "/UserReg/IUDUserRegtable";
        let _response = await Api.IUD(Param, url);

        showAlert(_response);
        setTimeout(() => {
            navigate('/')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };


    async function IUDOperation1() {
        var AadharNo = aadharnumber;
        var Name = name;
        var Dateofbirth = dob;
        var Mobilenumber = mobilenumber;
        var Email = email;
        var Qualification = qualification;
        var Aadhar = aadharnumber;
        var UploadFile = uploadFile;
        var SelectedQualiify = selectedQualiify;
        var Country = country;
        var State = state;
        var City = city;
        var Address = address;
        var Pincode = pincode;
        var Plan = selectedPlan;
        var Amount = amount;


        if (Name == "") {
            toast.error('Please enter a Name')
            return false;
        }

        if (Dateofbirth == "") {
            toast.error('Please Choose Date of Birth')
            return false;
        }



        if (Mobilenumber == "") {
            toast.error('Please Enter Mobile  Number ')
            return false;
        }
        else {
            if (!(Mobilenumber.length == 10)) {
                toast.error('Please Enter 10 Digit Mobile Number')
                return false;
            }
        }
        if (Email == "") {
            toast.error('Please Enter Email Id ')
            return false;
        }

        if (SelectedQualiify == "") {
            toast.error('Please Choose Qualification ')
            return false;
        }
        if (Country == "") {
            toast.error('Please Choose Country ')
            return false;
        }
        if (State == "") {
            toast.error('Please Choose State ')
            return false;
        }
        if (City == "") {
            toast.error('Please Choose City ')
            return false;
        }
        if (Address == "") {
            toast.error('Please Enter Address ')
            return false;
        }
        if (Pincode == "") {
            toast.error('Please Enter Pincode ')
            return false;
        }
        else {
            if (!(Pincode.length == 6)) {
                toast.error('Please Enter 6 Digit Pincode')
                return false;
            }
        }
        if (UploadFile == "") {
            toast.error('Please Choose Resume ')
            return false;
        }


        var Param = {

            "mstjobseekerid": ID,
            "mstjobseekercode": "string",
            "name": Name,
            "dataofbirth": Dateofbirth,
            "mobilenumber": Mobilenumber,
            "qualifcation": JSON.stringify(formFields12),
            "education": JSON.stringify(formFields),
            "usertype": "string",
            "email": Email,
            "aadhaarnumber": Aadhar,
            "filedocument": UploadFile,
            "filename": "string",
            "active": "string",
            "searchField": "string",
            "flag": "I",
            "createdBy": 0,
            "createdDate": "2022-11-28T10:02:17.804Z",
            "subscribe": "string",
            "plantype": "string",
            "planperiod": "string",
            "activestatus": "string",
            "planamount": 0,
            "mstqualifcation": SelectedQualiify,
            "country": Country,
            "state": State,
            "city": City,
            "pincode": Pincode,
            "address": Address,
            "plan": Plan


        }

        var url = "/JobSeekerTable/IUDJobSeekerTable";
        let _response = await Api.IUD(Param, url);

        showAlert(_response);
        setTimeout(() => {
            navigate('/')
        }, 2000)

    }




    const handleOnChange = (event, option, index, F) => {

        const values = [...ListMenu];
        if (event.target.checked) {
            if (F === "V") {
                values[index].View = 1;
            } if (F === "A") {
                values[index].Add = 1;
            } if (F === "E") {
                values[index].Edit = 1;
            } if (F === "D") {
                values[index].Delete = 1;
            } if (F === "P") {
                values[index].Print = 1;
            }
        }
        else {
            if (F === "V") {
                values[index].View = 0;
            } if (F === "A") {
                values[index].Add = 0;
            } if (F === "E") {
                values[index].Edit = 0;
            } if (F === "D") {
                values[index].Delete = 0;
            } if (F === "P") {
                values[index].Print = 0;
            }
        }
        setListMenu(values);

    };

    const [listdata, setlistdata] = useState([]);

    async function checkBoxMenuValidation(e) {
        set_Usertype(e.target.value);
        let _a1 = [];
        _a1.push(e.target.value)
        var Param = { mstuserregid: 0, rolecab: JSON.stringify(_a1) }
        var url = "/UserReg/GetUserRegtable";
        let _response1 = await Api.BindGrid(Param, url)
        setlistdata(_response1)
    }

    async function Close() {
        navigate("/");
    } 
    return (
        <div> 
            <div>
                <Toaster toastOptions={{ position: "top-right" }} />
                <SigninBase />
                <div class="card">
                    <div class="card-body">
                        <div class="divGroup col-md-12"> 
                            <p class="signupclr">Please Choose Any One to Create the Application</p>
                            <input type="radio" id="Employer" value="Employer" checked={_Usertype == "Employer"} onChange={(e) => checkBoxMenuValidation(e)} />
                            <label for="Employer" class="test">Employer</label>

                            <input type="radio" id="JobSeeker" value="JobSeeker" checked={_Usertype == "JobSeeker"} onChange={(e) => checkBoxMenuValidation(e)} />
                            <label for="JobSeeker" class="test">JobSeeker</label> 
                        </div> 
                        {_Usertype === "Employer" ?
                            <EmployerIndex />
                            : null}
                        {_Usertype == "JobSeeker" ?
                            <JobseekerIndex />
                            : null} 
                    </div>
                </div>
            </div>
        </div> 
    )
}