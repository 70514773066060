import React, { useState, useEffect } from 'react'
import Base from '../Base'
import Api from '../Services/ApiCall';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';



export default function Employerplancreation(any, i) {

    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstemployerplancreationid: 0 }
            var url = "/EmployerPlanCreation/BindEmployerPlancreation";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstemployerplancreationid, code: params.data.Plantype });
    };

    const columnDefs = [
        // { headerName: 'Id', field: 'Mstemployerplancreationid', filter: 'agNumberColumnFilter', enableFilter: true },
        { headerName: 'Plan Name', field: 'Plantype', filter: true, enableFilter: true },
        { headerName: 'Resume Download Amount', field: 'Plandetail', filter: true, enableFilter: true },
        { headerName: 'Job Post Amount', field: 'Amount', filter: true, enableFilter: true },
        { headerName: 'Job Apply Amount', field: 'Employerplancreationcode', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };



    async function IUD_Delete() {
        openPopup1();
    }


    const [plantype, setPlantype] = useState("");
    const [plandetail, setPlandetail] = useState("");
    const [amount, setAmount] = useState("");
    const [plandate, setPlandate] = useState("");
    const [tax, setTax] = useState("");
    const [job_Apply_Amount, setJob_Apply_Amount] = useState("");


    async function IUDOperation() {
        var Plantype = plantype;
        var Plandetail = plandetail;
        var Amount = amount;
        var Plandate = plandate;
        var Tax = job_Apply_Amount;

        var ID = selectedRowData.id;

        var Param = {
            "mstemployerplancreationid": ID,
            "employerplancreationcode": Tax,
            "plantype": Plantype,
            "plandetail": Plandetail,
            "amount": Amount,
            "active": "string",
            "searchField": Plandate,
            "flag": "U",
        };
        var url = "/EmployerPlanCreation/IUDEmployerPlanCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup(false);

    }
    async function IUDOperationDelete() {
        var Plantype = plantype;
        var Plandetail = plandetail;
        var Amount = amount;
        var Plandate = plandate;
        var Tax = job_Apply_Amount;

        var ID = selectedRowData.id;

        var Param = {
            "mstemployerplancreationid": ID,
            "employerplancreationcode": Tax,
            "plantype": Plantype,
            "plandetail": Plandetail,
            "amount": Amount,
            "active": "string",
            "searchField": Plandate,
            "flag": "D",
        };
        var url = "/EmployerPlanCreation/IUDEmployerPlanCreation";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setShowPopup1(false);

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        debugger
        var Param = { mstemployerplancreationid: id }
        var url = "/EmployerPlanCreation/BindEmployerPlanCreation";
        let _response = await Api.BindGrid(Param, url);
        setPlantype(_response[0].Plantype);
        setPlandetail(_response[0].Plandetail);
        setAmount(_response[0].Amount);
        setTax(_response[0].Employerplancreationcode);
        setJob_Apply_Amount(_response[0].Employerplancreationcode);
    }

    return (
        <div>
            <Base Employerplan={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    {/* <h1>Tables</h1> */}
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>

                            <li class="breadcrumb-item">Plan Creation</li>
                            <li class="breadcrumb-item active">Employerplan</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />
                {Api.CheckAdd(18) == 1 ?
                    <button type="button" class="btn btn-primary Add" style={{ background: '#0782d0ed' }}>
                        <Link to={'/employerplancreationform'}
                            state={{ ID: 0, Flag: 'I' }}
                            style={{ color: "white" }}
                        >
                            Add
                        </Link>
                    </button>
                    : null}
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">

                                <div class="col-md-6">
                                    <label for="planname">Plan Name</label>
                                    <input type='text'  class="form-control no-spinner" placeholder="Plan Name" value={plantype} onChange={(e) => setPlantype(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="plantype">Resume Download Amount</label>
                                    <input type='number'  class="form-control no-spinner" placeholder="Resume Download Amount" value={plandetail} onChange={(e) => setPlandetail(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="amount">Job Post Amount</label>
                                    <input type="number" class="form-control no-spinner"  value={amount} placeholder="Job Post Amount" onChange={(e) => setAmount(e.target.value)} />
                                </div>
                                <div class="col-md-6">
                                    <label for="amount">Job Apply Amount</label>
                                    <input type="number" class="form-control no-spinner"  value={job_Apply_Amount} placeholder="Job Post Amount" onChange={(e) => setJob_Apply_Amount(e.target.value)} />
                                </div>

                                <div class="text-center">
                                    {Api.CheckUpdate(18) == 1 ?
                                        <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>
                                        : null}
                                    {Api.CheckDelete(18) == 1 ?
                                        <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>
                                        : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        </div>
    )
}
