import React from 'react'
import Base from '../Base'
import { Images } from '../../Images/Index'
import { Link} from "react-router-dom";
import CSS from '../../App.css';

export default function Digitalsignature() {
  return (
    <>
    <Base Digitalsignature={true}/>
    <div id="main" class="main">
              <div class="pagetitle">
                
                  <nav>
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item"><Link to="/dash">Home</Link></li>
                          <li class="breadcrumb-item">Digital Signature</li>
                      </ol>
                  </nav>
              </div>
            {/* <div class="col-md-12"> */}
            <div class="row g-3">
              <div class="col-md-6">
              <img src={Images.dummyaadhar} alt="pelago"
            //    className="loginlg_img" 
               />
            </div>
            <div class="col-md-6">
              <img src={Images.aadhar} alt="pelago" className="digiimg" 
               />
              </div>
              </div>
              {/* <button class="btn btn-primary digibtn"> */}
              <button class="btn btn-light digibtn">
              <a href="https://paperlessdsc.e-mudhra.com/?X=TUJqdm50QlEva1ZXMkpxK3oycFhuQT09&Y=TE85RTNtRmZLOTA9">
              Apply Here
              </a>
              </button>

    </div>
    </>
  )
}
