import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Images } from '../Images/Index';
import { Link, useNavigate } from 'react-router-dom';
import LoginBase from './SigninBase';
import Swal from 'sweetalert2';
import Api from '../../src/Components/Services/ApiCall';
import {
    validEmailRegex,
    validPasswordRegex,
    validOnlyEmailRegex,
    validPhoneNumberRegex,
    numberMethod,
    maxLength
} from '../Components/CommanRules';
export default function Forgotpassword() {

    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    
    const [mailpassword, setMailPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const reset = {
        textAlign: "center"
    }
    useEffect(() => {




    }, []);


  

    async function sendmail() {

        var Emailid = email;
       

        var Param = {
            "emailid": Emailid,


            "flag": "MS",
            "searchField": "string",
            "createdBy": 0,
            "createdDate": "2022-10-20T13:39:25.626Z"
           
        }

        var url = "/UserReg/IUDUserRegtableResetPassword";
        let _response = await Api.IUD(Param, url);
     
        showAlert(_response)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };
    const showAlert1 = (_response) => {
        Swal.fire({
            title: "Success",
            text: "Password Successfully changed",
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function forgotpwd() {
        var Emailid = email;
        var Mailpassword = mailpassword;
        var Newpassword = newpassword;






       
        if (Mailpassword == "") {
            toast.error('Please Choose Qualification ')
            return false;
        } if (Newpassword == "") {
            toast.error('Please enter a update on date')
            return false;
        }


        var Param = {
            "emailid": Emailid,

            "password": Mailpassword,
            "flag": "FP",
            "confirmpassword": Newpassword,


            "createdBy": 0,
            "createdDate": "2022-10-20T13:39:25.626Z",
            "searchField": "string",

        }

        var url = "/UserReg/IUDUserRegtableResetPassword";
        let _response = await Api.IUD(Param, url);

        showAlert1()
        navigate('/')

    }
    const [showPassword, setShowPassword] = useState(false);
    return (
        <>
            <div>
                <LoginBase />
                <Toaster toastOptions={{ position: "top-right" }} />
                <section class="login-block">
                    <div class="container">
                        <div class="empcard1">
                            <div style={{ marginTop: "20px" }}>
                                <div class="row loginrow">
                                    <div class="col-md-4 login-sec ">
                                        {/* style={{ backgroundImage: `url(${Images.backgroundimg})` }} > */}
                                        {/* <div > */}
                                        <div className="Auth-form-content forget">
                                            <h3 className="Auth-form-title">
                                                <h4 class="login_letter">Forgot Password</h4>
                                                </h3>
                                                {/* <img src={Images.mainlogo} alt="pelago" className="loginlg_img" /></h3> */}
                                            <div className="form-group mt-3">
                                                <label>Enter Email Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control mt-1" autoComplete='off'
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter Your Email ID"
                                                    value={email}/>

                                                <div className="form-group mt-3">
                                                    <button type="button" className="btn btn-primary" onClick={sendmail}>
                                                        Send Email
                                                    </button>

                                                </div>
                                                <div className="form-group mt-3">
                                                    <label>  Enter Your Mail Verfication Code</label>
                                                    <input
                                                        required
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control mt-1" autoComplete='off'
                                                        onChange={(e) => setMailPassword(e.target.value)}
                                                        placeholder="Enter  Email password"
                                                        value={mailpassword} />
                                                    <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} onClick={() => setShowPassword(!showPassword)}></i>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <label>Enter New Password</label>
                                                    <input
                                                        required
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control mt-1"
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                        placeholder="Enter New Password"
                                                        value={newpassword} />
                                                    <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"} onClick={() => setShowPassword(!showPassword)}></i>
                                                </div>
                                                <div className="form-group mt-3">
                                                    <button type="button" className="btn btn-primary" onClick={forgotpwd} >
                                                        Submit
                                                    </button>
                                                    <button type="button" class="btn btn-info">
                                                        <Link to='/' class="backbuttonclr">
                                                            Back
                                                        </Link>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8 banner-sec">
                                        {/* <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                         */}
                                        {/* <div class="carousel-inner" role="listbox"> */}
                                        <div class="signin-img">
                                            <img class="d-block sign-img-1" src="https://static.pexels.com/photos/33973/pexels-photo.jpg" alt="First slide" />
                                            {/* <div class="carousel-caption d-none d-md-block">
                                                    <div class="banner-text">
                                                    </div>
                                                </div> */}
                                        </div>

                                        {/* </div> */}

                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
