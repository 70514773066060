import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Api from './Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import JobseekerBase from './Jobseeker/JobseekerBase'
import { MDBDataTable } from 'mdbreact';

export default function Jobseekerdashboard() {
  const [listdata, setlistdata] = useState("");
  const navigate = useNavigate()

  // async function BindGrid() {

  //   var Paycheck = "Paid";
  //   var Param = {
  //     Flag: Paycheck
  //   };
  //   var url = "/Postjobs/ViewJobs";
  //   let _response = await Api.BindGrid(Param, url)
  //   let Arry_Title1 = [];
  //   setlistdata(_response)
  // }
  const [walletAmount, setWalletAmount] = useState("");
  // const data = {
  //   columns: [
  //     {
  //       label: 'Company Name',
  //       field: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Subscribe'] == "Paid" ?
  //         'Companyname' : null,
  //       sort: 'asc',
  //       width: 150
  //     },
  //     {
  //       label: 'Job Title',
  //       field: 'Jobtitle',
  //       sort: 'asc',
  //       width: 270
  //     },
  //     {
  //       label: 'Education',
  //       field: 'Education',
  //       sort: 'asc',
  //       width: 270
  //     },
  //     {
  //       label: 'Vacancy',
  //       field: 'Vacancy',
  //       sort: 'asc',
  //       width: 200
  //     },
  //     {
  //       label: 'Experience Details',
  //       field: 'Experiencedetails',
  //       sort: 'asc',
  //       width: 150
  //     },
  //     {
  //       label: 'Industry Type',
  //       field: 'Industrytype',
  //       sort: 'asc',
  //       width: 100
  //     },
  //     {
  //       label: 'Location',
  //       field: 'Location',
  //       sort: 'asc',
  //       width: 150
  //     },
  //     {
  //       label: 'Salary',
  //       field: 'SearchField',
  //       sort: 'asc',
  //       width: 150
  //     }
  //   ],
  //   rows: listdata
  // };

  useEffect(() => {
    BindGrid();

  }, []);

  // function post() {
  //   navigate("/Jobs")
  // }
  // function subscribe() {
  //   navigate("/Rechargeform")
  // }
  async function BindGrid() {
    debugger;
    var Id = sessionStorage.getItem("MUserID")
    var Param = { Mstjobseekerid: Id, AccessType: 'Jobseeker' }
    var url = "/JobSeekerTable/BindJobSeekerTable";

    let _response = await Api.BindGrid(Param, url)
    setWalletAmount(_response[0].Recharge_amount)
  }



  return (

    <>
      <JobseekerBase />
      <div id="main" class="main">
        <Toaster toastOptions={{ position: "top-right" }} />
        <h1>Jobseeker Dashboard</h1>
        <div></div>
        <div className='row'>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <h2>Your Wallet Amount is</h2>
                <h3>{walletAmount}</h3>
              </div>
            </div>
          </div>
        </div>
        {/* <MDBDataTable
          striped
          bordered
          small
          data={data}
        />
        <div class="row">

          <div className="col-xl-2" key={0}></div>
          <div className="col-xl-8" key={0}>
            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                
                    <h2>Please Apply the Jobs here.</h2>
                    <button class="btn btn-primary Add" onClick={post}>Apply Job</button>
                  
              </div>
            </div>
          </div>
          <div className="col-xl-2" key={0}></div>
        </div> */}

      </div>
    </>


  )
}
