import React, { useState, useEffect } from 'react'
import { Images } from '../../Images/Index';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

export default function EmployerBase({ Appliedcandidate, Viewedcandidate }) {


  const [toggleSidebar, settoggleSidebar] = useState(false)
  const onclickToggleshow = (value) => {
    settoggleSidebar(value)
    if (value) {
      $('body').addClass('toggle-sidebar')
    }
    else {
      $('body').removeClass('toggle-sidebar')
    }
  }


  const [clickLogoutshow, setclickLogoutshow] = useState(false)
  const onclicklogoutshow = (showlogoutbtn) => {
    setclickLogoutshow(showlogoutbtn)
  }

  const [clickReportshow, setclickReportshow] = useState(false)
  const onclickReportshow = (showReportdropdown) => {
    setclickReportshow(showReportdropdown)
  }

  const [clickNotificationshow, setclickNotificationshow] = useState(false)
  const onclickNotificationshow = (showNotificationbtn) => {
    setclickNotificationshow(showNotificationbtn)
  }

  const [username, setusername] = useState("");
  useEffect(() => {
    setusername(sessionStorage.getItem("username"))

  });

  const logout = () => {
    navigate('/')
  }
  const navigate = useNavigate();


  return (
    <>
      <body class={toggleSidebar ? "toggle-sidebar" : ""} >

        <div id="header" class="header fixed-top d-flex align-items-center">

          <div class="d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src={Images.mainlogo} alt="pelago" className="userlg_img" />
            </a>

            <i class="bi bi-list toggle-sidebar-btn" onClick={() => onclickToggleshow(!toggleSidebar)}></i>

          </div>


          <nav class="header-nav ms-auto" >
            <ul class="d-flex align-items-center">




              <li class="nav-item dropdown pe-3">

                <a class={clickNotificationshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={(Notification) => onclickNotificationshow(!clickNotificationshow)} data-bs-toggle="dropdown">

                  <li class="dropdown-header">
                    <a class="nav-link nav-icon" data-bs-toggle="dropdown" >

                      <i class="bi bi-bell"  ></i>

                      {/* {JSON.parse(sessionStorage.getItem('userdetails'))[0]['Subscribe'] == "Paid" ? */}
                      <span class="badge bg-primary badge-number">0</span>
                      {/* :
                        <span class="badge bg-primary badge-number">1</span>
                      } */}
                    </a>
                  </li>
                </a>
                {/* {JSON.parse(sessionStorage.getItem('userdetails'))[0]['Subscribe'] == "Paid" ?
                  null :
                  <ul id="Profiledropdown" class={clickNotificationshow ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"} style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-16px, 38px)" }}>
                    <li class="dropdown-header">


                      <li class="dropdown-header">
                        You have 1 new notifications
                        <a ><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                      </li>

                      <li>
                        <hr class="dropdown-divider"></hr>

                      </li>
                      <li class="notification-item">
                        <i class="bi bi-exclamation-circle text-warning"></i>

                        <div>
                          <h4>Plan Expire</h4>
                          <p>Your Plan is Expire Please Subscribe.</p>
                        </div>
                      </li>
                    </li>
                  </ul>
                } */}
              </li>

              <li class="nav-item dropdown pe-3">

                <a class={clickLogoutshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={() => onclicklogoutshow(!clickLogoutshow)} data-bs-toggle="dropdown">
                  <i class="bi bi-person-circle"></i>
                  <span class="d-none d-md-block dropdown-toggle ps-2">{username} </span>
                </a>


                <ul id="Profiledropdown" class={clickLogoutshow ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"} style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-16px, 38px)" }}>
                  <li class="dropdown-header">
                    <h6></h6>
                    <span>
                      {sessionStorage.getItem("roleaUsertypeccess")}
                    </span>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li>
                    <Link to='/Myprofile' state={{ ID: sessionStorage.getItem("MUserID"), Flag: 'EmpProf' }}>

                      <a class="dropdown-item d-flex align-items-center" href="" >

                        <i class="bi bi-person"></i>

                        <span>My Profile</span>

                      </a>
                    </Link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>


                  <li>
                    <a class="dropdown-item d-flex align-items-center" >
                      <i class="bi bi-box-arrow-right"></i>
                      <span onClick={logout}>Sign Out</span>
                    </a>
                  </li>
                </ul>

              </li>


            </ul>
          </nav>


        </div>

        <div id="sidebar" class="sidebar">

          <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
              <a

                class="nav-link " href='/Employerdashboard'>
                <i class="bi bi-grid"></i>
                <span>Home</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link " href='/Postjobstable'>
                <i class="bi bi-grid"></i>
                <span>Post Jobs</span>
              </a>
            </li>

            <li class="nav-item">
              <Link to={'/Jobseekerlists'}
                class="nav-link " state={{ ID: 0, Flag: 'I' }}>
                <i class="bi bi-grid"></i>
                <span>Jobseeker Lists</span>
              </Link>
            </li>

            <li class="nav-item">
              <a class={clickReportshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickReportshow(!clickReportshow)} data-bs-toggle="collapse" >
                <i class="bi bi-file-earmark-pdf"></i><span>Reports</span><i class="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul id="components-nav"
                class={clickReportshow || Appliedcandidate ? "nav-content collapse show" : "nav-content collapse"}
                data-bs-parent="#sidebar-nav">
                <ul>
                  <li
                    class={Appliedcandidate ? "list" : ""}
                  >
                    <Link to='/Appliedcandidate'>
                      <i class="bi bi-circle"></i><span> Applied/View Report </span>
                    </Link>
                  </li>

                </ul>
              </ul>
            </li>
            <li class="nav-item">
              <Link class="nav-link " to={'/Rechargeform'}
                state={{ Code: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'] }}>
                <i class="bi bi-grid"></i>
                <span>Topup</span>
              </Link>
            </li>
            <li class="nav-item">
              <Link to={'/Customersupporttable'}

                class="nav-link " state={{ ID: 0, Flag: 'EmpCS' }}>
                <i class="bi bi-telephone-x"></i>
                <span>Customer Support</span>
              </Link>
            </li>
          </ul>
        </div>
      </body>
    </>
  )
}
