import React, { useState, useEffect } from 'react'
import Base from '../Base'
import Api from '../Services/ApiCall';
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";
import { Globalfilter } from "./Admin_form/Filter/Globalfilter";
import '../../Css/Pagination.css'
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';



export default function Master(any, i) {
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup1, setShowPopup1] = useState(false);
    const [master, setMaster] = useState("");
    const [master_value, setMaster_value] = useState("");
    const [masteredit_value, setMasterEdit_value] = useState("");
    const [masteredit, setMasteredit] = useState("");

    useEffect(() => {
        fetchDataAndSetState();
    }, []);

    const fetchDataAndSetState = async () => {
        try {
            var Param = { mstuserid: 0 }
            var url = "/CommanAPI/GetMaster";
            let _response1 = await Api.BindGrid(Param, url);
            setRowData(_response1);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openPopup = (rowData) => {
        setSelectedRowData(rowData);
        Get(rowData.id)
        setShowPopup(true);
    };
    const openPopup1 = (rowData) => {
        setShowPopup(false);
        setShowPopup1(true);
    };

    const closePopup1 = () => {
        setSelectedRowData(null);
        setShowPopup1(false);
    };
    const closePopup = () => {
        setSelectedRowData(null);
        setShowPopup(false);
    };

    const onCellClicked = (params) => {
        openPopup({ id: params.data.Mstuserid, code: params.data.Master_name });
    };

    const columnDefs = [
        { headerName: 'Master_name', field: 'Master_name', filter: true, enableFilter: true },
        { headerName: 'Value', field: 'Value', filter: true, enableFilter: true },
    ];

    const [quickFilterText, setQuickFilterText] = useState("");

    const onQuickFilterChange = (event) => {
        setQuickFilterText(event.target.value);
    };

    async function IUD_Delete() {
        openPopup1();
    }
    
    async function IUDOperationInsert() {

        var Param = {
            "mstuserid": 0,
            "master_name": master,
            "value": master_value,
            "flag": "I",
        };
        var url = "/CommanAPI/CreateMaster";
        let _response = await Api.IUD(Param, url);
        showAlert(_response.Message);
        fetchDataAndSetState()
        setShowPopup(false);
        setMaster("")
        setMaster_value("")
        setMasteredit("")
        setMasterEdit_value("")

    }
    async function IUDOperation() {


        var ID = selectedRowData.id;

        var Param = {
            "mstuserid": ID,
            "master_name": masteredit,
            "value": masteredit_value,
            "flag": "U",
        };
        var url = "/CommanAPI/CreateMaster";
        let _response = await Api.IUD(Param, url);
        showAlert(_response.Message);
        fetchDataAndSetState()
        setShowPopup(false);
        setMaster("")
        setMaster_value("")
        setMasteredit("")
        setMasterEdit_value("")

    }
    async function IUDOperationDelete() {


        var ID = selectedRowData.id;

        var Param = {
            "mstuserid": ID,
            "master_name": masteredit,
            "value": masteredit_value,
            "flag": "D",
        };
        var url = "/CommanAPI/CreateMaster";
        let _response = await Api.IUD(Param, url);
        showAlert(_response.Message);
        fetchDataAndSetState()
        setShowPopup1(false);
        setMaster("")
        setMaster_value("")
        setMasteredit("")
        setMasterEdit_value("")

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function Get(id) {
        setMaster("")
        setMaster_value("")
        setMasteredit("")
        setMasterEdit_value("")
        var Param = { Mstuserid: id }
        var url = "/CommanAPI/GetMaster";
        let _response = await Api.BindGrid(Param, url)
        setMasteredit(_response[0].Master_name);
        setMasterEdit_value(_response[0].Value);
    }

    return (
        <div>
            <Base Master={true} />
            <div id="main" class="main">
                <div class="pagetitle">
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link to="/dash">Home</Link>
                            </li>
                            <li class="breadcrumb-item">Master</li>
                            <li class="breadcrumb-item active">Master Creation</li>
                        </ol>
                    </nav>
                </div>
                <input
                    type="text"
                    placeholder="Search..."
                    onChange={onQuickFilterChange}
                    style={{ marginBottom: '10px' }}
                />

                <div class="row g-3 mb-3">

                    <div class="col-md-5 mt-4">
                        <label for="plantype">Master Name</label>
                        <select id="inputstate" class="form-select" value={master} onChange={(e) => setMaster(e.target.value)}>
                            <option value=''>Select</option>
                            <option value='Experience'>Experience</option>
                            <option value='Sex'>Sex</option>
                            <option value='Salary'>Salary</option>
                        </select>
                    </div>

                    <div class="col-md-5 mt-4">
                        <label for="plandetails">Value</label>
                        <input type="text" class="form-control no-spinner" value={master_value} placeholder="Value" onChange={(e) => setMaster_value(e.target.value)} />
                    </div>
                    <div class="col-md-2 mt-5">
                        <button type="button" class="btn btn-primary Add" onClick={IUDOperationInsert} style={{ background: '#0782d0ed' }}>
                            Add
                        </button>
                    </div>
                </div>

                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        domLayout='autoHeight'
                        floatingFilter={true}
                        enableBrowserTooltips={true}
                        onCellClicked={onCellClicked}
                        quickFilterText={quickFilterText}
                    />
                </div>
                {showPopup && (
                    <Modal show={true} onHide={closePopup} dialogClassName="custom-modal">
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <label for="plantype">Master Name</label>
                                    <select id="inputstate" class="form-select" value={masteredit} onChange={(e) => setMasteredit(e.target.value)}>
                                        <option value=''>Select</option>
                                        <option value='Experience'>Experience</option>
                                        <option value='Sex'>Sex</option>
                                        <option value='Salary'>Salary</option>
                                    </select>
                                </div>

                                <div class="col-md-6">
                                    <label for="plandetails">Value</label>
                                    <input type="text" class="form-control no-spinner" value={masteredit_value} placeholder="Value" onChange={(e) => setMasterEdit_value(e.target.value)} />
                                </div>


                                <div class="text-center">
                                    <button type="button" class="btn btn-primary" onClick={IUDOperation}>Update</button>

                                    <button type="reset" class="btn btn-info" onClick={IUD_Delete} >Delete</button>

                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {showPopup1 && (
                    <Modal show={true} onHide={closePopup1} >
                        <Modal.Header closeButton>
                            <Modal.Title> {selectedRowData.code}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are You sure want to Delete?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closePopup1}>
                                Close
                            </Button>
                            <Button variant="secondary" onClick={IUDOperationDelete}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>


        </div>
    )
}
