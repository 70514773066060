import React, { useState, useEffect } from 'react'
import Api from '../Services/ApiCall';
import Base from '../Base';

export default function AgentDashboard() {

    const [commissiondata, setCommissiondata] = useState([]);
    const [cmmissionDatedata, setCommissionDatedata] = useState([]);
    const [walletAmount, setWalletAmount] = useState("");



    useEffect(() => {
        bindCommision();
        bindDateCommision();
        GetWallet();
    }, []);

    async function bindDateCommision() {
        var Id = sessionStorage.getItem("MUserID");
        const currentDate = new Date().toISOString();
        var d = new Date;

        let month = d.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }

        let second = d.getSeconds();
        if (second < 10) {
            second = '0' + second;
        }
        var Hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
        var date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
        var minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()

        let curdate = d.getFullYear() + '-' + month + '-' + date + 'T' + Hour + ":"
            + minute + ":" + second + ".965Z";
        const url = `/Dashboard/BindAgentCommissionDate?Id=${Id}&date=${curdate}`;
        let _response1 = await Api.BindDropdown(url)
        setCommissionDatedata(_response1)
    }
    async function bindCommision() {
        var Id = sessionStorage.getItem("MUserID")
        var url = "/Dashboard/BindAgentCommission?Id=" + Id;
        let _response1 = await Api.BindDropdown(url)
        setCommissiondata(_response1)
    }
    async function GetWallet() {
        debugger;
        var Id = sessionStorage.getItem("MUserID")
        var Param = { Mstuserregid: Id}
        var url = "/UserReg/GetUserRegtable1";

        let _response = await Api.BindGrid(Param, url)
        setWalletAmount(_response[0].Planamount)
    }

    return (
        <div>
            <Base />
            <div id="main" class="main">
                <h1>Agent Dashboard</h1>
                <div></div>
                <div className='row'>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Your Wallet Amount is</h2>
                                <h3>{walletAmount}</h3>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Your Total Employer Registration Commissions</h2>
                                <h3>{commissiondata.commisiondeduction ? commissiondata.commisiondeduction : 0}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Your Total Jobseeker Registration Commissions</h2>
                                <h3>{commissiondata.commisionvalue ? commissiondata.commisionvalue : 0}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Your Today Employer Registration Commissions</h2>
                                <h3>{cmmissionDatedata.commisiondeduction ? cmmissionDatedata.commisiondeduction : 0}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <h2>Your Today Jobseeker Registration Commissions</h2>
                                <h3>{cmmissionDatedata.commisionvalue ? cmmissionDatedata.commisionvalue : 0}</h3>
                            </div>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
    )
}