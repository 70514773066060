import React, { useState, useEffect } from 'react'
import JobseekerBase from '../Jobseeker/JobseekerBase';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Api from '../Services/ApiCall';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Button, Modal } from 'react-bootstrap';
import {
    validEmailRegex, validPasswordRegex,
    validOnlyEmailRegex, validPhoneNumberRegex, numberMethod, maxLength
} from '../CommanRules';

export default function Jobseeker_profile() {
    const navigate = useNavigate();
    const location = useLocation();
    const { Flag1 } = location.state;
    const Flag = 'U';
    const ID = sessionStorage.getItem('MUserID')

    useEffect(() => {
        if (ID != 0) {
            BindGrid(ID)
            Get(ID)
        }
        GetQualification()
        GetPlandetail()
        GetAmount()
        GetCountryList()
        GetCityList()
        GetDistrict()
    }, []);
    const [aadharnumber, setAadharno] = useState("");
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [uploadFile, setuploadFile] = useState("");
    const [mobilenumber, setMobilenumber] = useState("");
    const [email, setEmail] = useState("");
    const [qualification, setQualification] = useState([]);
    const [filename, setfilename] = useState("");
    const [plan, setPlan] = useState("");
    const [amount, setAmount] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [CountryList, setCountryList] = useState();
    const [StateList, setStateList] = useState();
    const [CityList, setCityList] = useState();
    const [selectedPlan, setselectedPlan] = useState("");
    const [selectedQualiify, setselectedQualiify] = useState([]);
    const [usercode, setUserCode] = useState(JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekercode']);
    const [formFields12, setFormFields] = useState([
        { companyname: '', designation: '', years: '', month: '' },
    ])
    const [formFields, setFormField] = useState([
        { qualify: '', course: '', institution: '', passedout: '' },
    ])
    const handleFormChange = (event, index, F) => {
        let data = [...formFields12];
        if (F == 1) {
            data[index].companyname = event.target.value;
        } if (F == 2) {
            data[index].designation = event.target.value;
        } if (F == 3) {
            data[index].years = event.target.value;
        } if (F == 4) {
            data[index].month = event.target.value;
        }
        setFormFields(data);
    }
    const handleFormChange1 = (event, index, F) => {
        let data = [...formFields];
        if (F == 1) {
            data[index].qualify = event.target.value;
        } if (F == 2) {
            data[index].course = event.target.value;
        } if (F == 3) {
            data[index].institution = event.target.value;
        } if (F == 4) {
            data[index].passedout = event.target.value;
        }
        setFormField(data);
    }
    const submit = (e) => {
        e.preventDefault();
    }
    const addFields = () => {
        let object = { companyname: '', designation: '', years: '', month: '' }
        setFormFields([...formFields12, object])
    }
    const removeFields = (index) => {
        let data = [...formFields12];
        data.splice(index, 1)
        setFormFields(data)
    }
    const addFields1 = () => {
        let object = { qualify: '', course: '', institution: '', passedout: '' }
        setFormField([...formFields, object])
    }
    const removeFields1 = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormField(data)
    }
    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        // var url = "/DropDown/GetCities";
        // let _response = await Api.BindDropdown(url)
        // let Arry_Title1 = [];
        // setCityList(_response)
    }
    const [district, setDistrict] = useState("");
    const [districtlist, setDistrictlist] = useState("")
    async function GetDistrict() {
        var url = "/DropDown/GetDistrict";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setDistrictlist(_response)
    }
    async function GetPlandetail() {
        var url = "/DropDown/GetPlandetail";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setPlan(_response)
    }
    async function GetAmount(e) {
        setselectedPlan(e.target.value)
        var id = (e.target.value)
        var url = "/DropDown/GetPlandetailamount?id=" + id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setAmount(_response)
    }
    async function GetQualification() {
        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    async function BindGrid(id) {
        var Param = { mstjobseekerid: ID }
        var url = "/JobSeekerTable/BindJobSeekerTable";
        let _response = await Api.BindGrid(Param, url)
        var Files = _response[0].Filedocument;
        var type = Files.split(";")[0].split('/')[1];
        var test = Files.split(",")[1];
        setfile(test);
        setfiletype(type)
        setfilename(_response[0].Filename)
    }
    const uploadImage = async (e) => {
        const file = e.target.files[0];
        var filename = file.name;
        setfilename(filename)
        const base64 = await convertBase64(file);
        setuploadFile(base64);
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }
    const [agree, setAgree] = useState(false);
    async function IUDOperation() {
        var AadharNo = aadharnumber;
        var Name = name;
        var Dateofbirth = dob;
        var Mobilenumber = mobilenumber;
        var Email = email;
        var Qualification = qualification;
        var Aadhar = aadharnumber;
        var UploadFile = uploadFile;
        var SelectedQualiify = selectedQualiify;
        var Country = country;
        var State = state;
        var City = city;
        var Address = address;
        var Pincode = pincode;
        var Plan = selectedPlan;
        var Amount = amount;
        var code = (JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstjobseekercode'])

        var Param = {
            "mstjobseekerid": ID,
            "mstjobseekercode": code,
            "name": Name,
            "dataofbirth": Dateofbirth,
            "mobilenumber": Mobilenumber,
            "qualifcation": JSON.stringify(formFields12),
            "education": JSON.stringify(formFields),
            "usertype": "string",
            "email": Email,
            "aadhaarnumber": Aadhar,
            "filedocument": UploadFile,
            "filename": "string",
            "active": "string",
            "searchField": "string",
            "flag": Flag,
            "createdBy": 0,
            "createdDate": "2022-11-28T10:02:17.804Z",
            "subscribe": "string",
            "plantype": "string",
            "planperiod": "string",
            "activestatus": "string",
            "planamount": 0,
            "mstqualifcation": SelectedQualiify,
            "country": Country,
            "state": State,
            "city": City,
            "pincode": Pincode,
            "address": Address,
            "plan": Plan,
            "district": district,
        };
        var url = "/JobSeekerTable/IUDJobSeekerTable";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/Jobseekerdashboard')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };
    const [file, setfile] = useState()
    const [filetype, setfiletype] = useState()
    function base64toPDF(data, types) {
        var bufferArray = base64ToArrayBuffer(data);
        var blobStore = new Blob([bufferArray]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobStore);
            return;
        }
        var data = window.URL.createObjectURL(blobStore);
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = data;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(data);
        link.remove();
    }
    function base64ToArrayBuffer(data) {
        var data1 = data.replace('data:documents/pdf;base64,', '');
        var bString = window.atob(data1);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
            var ascii = bString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };
    async function Get(id) {
        var Param = { Mstjobseekerid: id }
        var url = "/JobSeekerTable/BindJobSeekerTable";
        let _response = await Api.BindGrid(Param, url)
        setAadharno(_response[0].Aadhaarnumber);
        setName(_response[0].Name);
        setDob(_response[0].Dataofbirth);
        setMobilenumber(_response[0].Mobilenumber);
        setEmail(_response[0].Email);
        setFormFields(JSON.parse(_response[0].Qualifcation));
        setselectedQualiify(_response[0].Mstqualifcation);
        setCountry(_response[0].Country);
        setState(_response[0].State);
        setCity(_response[0].City);
        setDistrict(_response[0].District)
        setAddress(_response[0].Address);
        setPincode(_response[0].Pincode);
        setselectedPlan(_response[0].Plan);
        setAmount(_response[0].Planamount);
        setfilename(_response[0].Filename)
        var Files = _response[0].Filedocument;
        var base64String = Files.split(',')[1];  
        var type = Files.split(';')[0].split('/')[1];
        setfiletype(type);
        setfile(base64String);
        setFormField(JSON.parse(_response[0].Education));
    }
    const [formValues, setFormValues] = useState([{ responses: "" }])
    let newFormValues = [];
    let addFormFields = () => {
        setFormValues([...formValues, { prospect_statement: "" }])
    }
    const [add, setAdd] = useState("")

    const [new_password, setnew_password] = useState("");
    const [confirm_password, setconfirm_password] = useState("");
    const [popup, setpopup] = useState(false);
    const [AppUserid, setAppUserid] = useState('');

    const openPopup = (id) => {
        setAppUserid(id)
        setpopup(true);
    }
    function closeModal2() {
        setpopup(false);
    }

    async function Active() {
        if (confirm_password != new_password) {
            toast.error('Password and Confirm Password Mismatch')
            return false;
        }
        var Param = {
            "mstuserregid": AppUserid.toString(),
            "flag": "jobseeker",
            "confirmpassword": confirm_password
        };
        var url = "/UserReg/UserForgotPassword";
        let _response = await Api.IUD(Param, url);
        showAlert1(_response);
        Get(0)
        setpopup(false);
        navigate("/")
    }

    const showAlert1 = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    const downloadResume = () => {
        try {
            const byteCharacters = atob(file); // Decode base64 string
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: `application/${filetype}` });
            
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'resume.pdf'; // Fallback to a default filename if not provided
            link.click();

            // Cleanup: revoke the object URL to avoid memory leaks
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error("Error decoding base64 string:", error);
        }
    }
    
    async function GetCityValue(value) {
        setDistrict(value)
        var Param = { districtName: value,cityName:"" }
        var url = "/CommanAPI/GetCity";
        let _response = await Api.BindGrid(Param, url)
        setCityList(_response)
    }


    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            <div>
                < JobseekerBase />
                <div id="main" class="main">
                    <div class="pagetitle">
                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><Link to="/Jobseekerdashboard">Home</Link></li>
                                <li class="breadcrumb-item">Jobseeker Profile</li>
                            </ol>
                        </nav>
                    </div>

                    <section class="section profile">
                        <div class="row">
                            <div class="col-xl-4">
                                <div class="card">
                                    <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                        {/* <img src={Images.man} alt="pelago" className="rounded-circle" />  */}
                                        {/* <img src="assets/img/man.jpg" alt="Profile" class="rounded-circle"/> */}
                                        {/* <span class="rounded-circle "><i class="bi bi-book-fill jobicon"></i></span> */}
                                        <h2>{sessionStorage.getItem('username')}</h2>
                                        <h3>{sessionStorage.getItem('AccessType')}</h3>
                                        <div class="social-links mt-2">
                                            <a class="twitter"><i class="bi bi-twitter"></i></a>
                                            <a class="facebook"><i class="bi bi-facebook"></i></a>
                                            <a class="instagram"><i class="bi bi-instagram"></i></a>
                                            <a class="linkedin"><i class="bi bi-linkedin"></i></a>
                                        </div>
                                        <div class='mt-3'>
                                            <button className='btn btn-info' onClick={() => openPopup(sessionStorage.getItem('MUserID'))} style={{ color: '#fff' }}>Change Password</button>
                                        </div>
                                        <div class='mt-3'>
                                            <button className='btn btn-info' onClick={downloadResume}>Download Resume</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-8">

                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Jobseeker Creation</h5>
                                        <div class="row g-3">
                                            <div class="col-md-6">
                                                <label for="code">User Code</label>
                                                <input type="text" class="form-control" disabled={true} placeholder="User code" value={usercode} onChange={(e) => setUserCode(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="name"> Name</label>
                                                <input type="text" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="aadharnumber">Aadhar Number</label>
                                                <input type="text" class="form-control no-spinner" disabled={true} value={aadharnumber} placeholder="Aadhar no" onChange={(e) => setAadharno(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="dob"> Date of Birth</label>
                                                <input type="date" max={new Date().toISOString().split("T")[0]} class="form-control" value={dob} disabled={Flag == "D" || Flag == "null" ? true : false} placeholder="Date of birth" onChange={(e) => setDob(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="mobilenumber"> Mobile Number</label>
                                                <input type="number" class="form-control no-spinner" placeholder="Mobile number" disabled={Flag == "D" || Flag == "null" ? true : false} value={mobilenumber} onChange={(e) => setMobilenumber(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="email">Email Id</label>
                                                <input type="email" class="form-control" placeholder="Email" value={email} disabled={true} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="qualification"> Qualifcation</label>
                                                <select id="inputState" class="form-select" value={selectedQualiify} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setselectedQualiify(e.target.value)}  >
                                                    <option selected>Qualification</option>
                                                    {qualification &&
                                                        qualification.map((i, index) => (
                                                            <option value={i.id}>{i.Text}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="country">Country</label>
                                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={country} onChange={(e) => GetStateList(e.target.value)}>
                                                    <option selected>Country</option>
                                                    {CountryList?.map((i, index) => (
                                                        <option value={i.country.toLowerCase()}>{i.country}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="state">State</label>
                                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={state} onChange={(e) => GetCityList(e.target.value)}>
                                                    <option selected >State</option>
                                                    {StateList?.map((i, index) => (
                                                        <option value={i.name.toLowerCase()}>{i.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="district">District</label>
                                                <select id="inputState" class="form-select" value={district} onChange={(e) => GetCityValue(e.target.value)}>
                                                    <option selected >District</option>
                                                    {districtlist &&
                                                        districtlist.map((i, index) => (
                                                            <option value={i.Text}>{i.Text}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="city">City</label>
                                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={city} onChange={(e) => setCity(e.target.value)}>
                                                    <option >City</option>
                                                    {CityList?.map((i, index) => (
                                                        <option value={i.CityName}>{i.CityName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="address">Address</label>
                                                <textarea class="form-control" placeholder="Address" value={address} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setAddress(e.target.value)} ></textarea>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="pincode">Pincode</label>
                                                <input type="number" class="form-control no-spinner" maxLength={6} placeholder="Pincode" disabled={Flag == "D" || Flag == "null" ? true : false} value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                            </div>
                                            <div class="col-md-6">
                                                <label for="attachresume">Attach Resume</label>
                                                <input type="file" name="file" class="form-control" disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => uploadImage(e)} />
                                            </div>
                                            {/* <h5>Add Your Education Details</h5>
                                            <div class="row g-3">
                                                {formFields.map((form1, index) => {
                                                    return (
                                                        <div className='row col-md-12' key={index}>
                                                            <div className=' row col-md-10'>
                                                                <div className=' col-md-3'>
                                                                    <label for="qualification"> Qualifcation</label>
                                                                    <input
                                                                        name='qualify' class="form-control col-md-3"
                                                                        placeholder=' Qualification'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange1(event, index, 1)}
                                                                        value={form1.qualify}
                                                                    /></div>
                                                                <div className=' col-md-3'>
                                                                    <label for="course"> Course</label>
                                                                    <input
                                                                        name='course' class="form-control col-md-3"
                                                                        placeholder='Course'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        value={form1.course} onChange={event => handleFormChange1(event, index, 2)}

                                                                    />
                                                                </div>
                                                                <div className=' col-md-3'>
                                                                    <label for="instituionname">Institution Name</label>
                                                                    <input
                                                                        name='institution' class="form-control col-md-3"
                                                                        placeholder='Institution Name'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange1(event, index, 3)}
                                                                        value={form1.institution}
                                                                    /></div>
                                                                <div className=' col-md-3'>
                                                                    <label for="name">Passedout Year</label>
                                                                    <input
                                                                        name='passedout' type="date" class="form-control col-md-3"
                                                                        placeholder='PassedOut year'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange1(event, index, 4)}
                                                                        value={form1.passedout}
                                                                    /></div>
                                                            </div>
                                                            <div className=' col-md-2'>
                                                                <button className='btn btn-warning' onClick={() => removeFields1(index)}>X</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className='col-md-3'>     <button class="btn btn-primary" onClick={addFields1}>Add</button></div>
                                            </div>
                                            <h5>Add Your Experience Details</h5>
                                            <div class="row g-3">
                                                {formFields12.map((form, index) => {
                                                    return (
                                                        <div className='row col-md-12' key={index}>
                                                            <div className=' row col-md-10'>
                                                                <div className=' col-md-3'>
                                                                    <label for="companyname">Company Name</label>
                                                                    <input
                                                                        name='CompanyName' class="form-control col-md-3"
                                                                        placeholder='CompanyName'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange(event, index, 1)}
                                                                        value={form.companyname}
                                                                    /></div>
                                                                <div className=' col-md-3'>
                                                                    <label for="designation">Designation</label>
                                                                    <input
                                                                        name='Designation' class="form-control col-md-3"
                                                                        placeholder='Designation'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange(event, index, 2)}
                                                                        value={form.designation}
                                                                    /></div>  <div className=' col-md-3'>
                                                                    <label for="from"> From</label>
                                                                    <input
                                                                        name='Year' type="month" class="form-control col-md-3"
                                                                        placeholder='Year'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange(event, index, 3)}
                                                                        value={form.years}
                                                                    /></div>
                                                                <div className=' col-md-3'>
                                                                    <label for="to">To</label>
                                                                    <input
                                                                        name='Month' type="month" class="form-control col-md-3"
                                                                        placeholder='Month'
                                                                        disabled={Flag == "D" || Flag == "null" ? true : false}
                                                                        onChange={event => handleFormChange(event, index, 4)}
                                                                        value={form.month}
                                                                    /></div>
                                                            </div>
                                                            <div className=' col-md-2'>
                                                                <button className='btn btn-warning' onClick={() => removeFields(index)}>X</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className='col-md-3'><button class="btn btn-primary" onClick={addFields}>Add</button></div>
                                            </div> */}

                                            <div class="text-center">
                                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                                <button type="reset" class="btn btn-info"><Link to="/Jobseekerdashboard">Back</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Modal show={popup} className='model-popup-design' style={{ marginBottom: "100px" }}>
                <Modal.Header classname="Userlgnmodalhead">
                    <Modal.Title>
                        Change Password
                    </Modal.Title>
                    <button type="button" class="btn-close" data-dismis="modal" onClick={closeModal2} arial-label="Close"></button>
                    {/* <CloseButton variant="black" onClick={closeModal2} /> */}
                </Modal.Header>
                <div className='loginmodalbody' style={{ width: '785px' }}>
                    <Modal.Body className='testing-test' >
                        <form>
                            <h5>Do you want to Change Password?</h5>
                        </form>
                    </Modal.Body>
                </div>
                <Modal.Footer>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label>New Password</label>
                            <input type='text' class='form-control' value={new_password} onChange={(e) => setnew_password(e.target.value)}></input>
                        </div>
                        <div className='col-md-6'>
                            <label>Confirm Password</label>
                            <input type='password' class='form-control' value={confirm_password} onChange={(e) => setconfirm_password(e.target.value)}></input>
                        </div>
                    </div>
                    <Button variant="secondary" onClick={() => Active()}>
                        Submit
                    </Button>
                    {/* <Button variant="secondary" onClick={() => Inactive()}>
                        Inactive
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
