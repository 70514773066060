import React from 'react'
import EmployerBase from '../../Employer/EmployerBase';
import Api from '../../Services/ApiCall'
import { Link, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react'
import Base from '../../Base'
import Swal from 'sweetalert2';
import $ from 'jquery';


export default function Jobtitleform() { 
    useEffect(() => {
        GetCountryList()
        GetEmployeetype()
        GetQualification()
        GetEmployerCode()
        GetCityList()
        GetExperience()
        GetSex()
        GetSalary()
        if (ID != 0) {
            Get(ID)
        }
    }, []);

    async function GetEmployeetype() {

        var url = "/DropDown/GetEmploymentType";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setEmptype(_response)
    }

    


    const location = useLocation();
    const { ID, Flag } = location.state;
    const navigate = useNavigate();
    const [jobtitle, setJobtitle] = useState("");
    const [companyname, setCompanyname] = useState(
        sessionStorage.getItem("AccessType") == "Employer" ?
            JSON.parse(sessionStorage.getItem('userdetails'))[0]['Companyname']
            : ""

    );
    const [employercode, setEmployercode] = useState(
        sessionStorage.getItem("AccessType") == "Employer" ?
            JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode']
            : ""

    );
    console.log(employercode, "employercode")
    const [experiencedetails, setExperiencedetails] = useState("");
    const [vacancies, setVacancies] = useState("");
    const [disclosefromdate, setDisclosefromdate] = useState("");
    const [disclosetodate, setDisclosetodate] = useState("");
    const [locate, setLocation] = useState("");
    const [skills, setSkills] = useState("");
    const [jobdescription, setJobdescription] = useState("");
    const [industrytype, setIndustrytype] = useState("");
    const [functionalarea, setFunctionalarea] = useState("");
    const [role, setRole] = useState("");
    const [rolecategory, setRolecategory] = useState("");
    const [employmenttype, setEmploymenttype] = useState("");
    const [education, setEducation] = useState("");
    const [salary, setSalary] = useState("");
    const [brief, setBrief] = useState("");
    const [CountryList, setCountryList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [qualification, setQualification] = useState([]);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [emptype, setEmptype] = useState("");
    const [employercodelist, setEmployercodelist] = useState("");
    const [companynamelist, setCompanynamelist] = useState("");

    const [sexList, setSexList] = useState([]);
    const [experienceList, setExperienceList] = useState([]);
    const [salaryList, setSalaryList] = useState([]);


    async function GetCountryList() {
        let _response = await Api.DirectApiCall('country', "", "")
        setCountryList(_response)
    }
    async function GetStateList(value) {
        setCountry(value)
        var Param = { country: value }
        let _response = await Api.DirectApiCall('state', value)
        setStateList(_response.states)
    }
    async function GetCityList(value) {
        setState(value)
        // let _response = await Api.DirectApiCall('city', country, value)
        // setCityList(_response)
        var url = "/DropDown/GetCities";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCityList(_response)
    }
    async function GetEmployerCode() {
        let id = "";
        if (sessionStorage.getItem("AccessType") === 'Agent') {
            id = sessionStorage.getItem("MUserID");
        }else{
            id="called";
        }
        var url = "/DropDown/GetEmployerCode?Agent="+id;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setEmployercodelist(_response)
    }

    async function GetCompanyName(e) {
        var Employercode = e; 
        var url = "/DropDown/GetEmployerName?code=" + Employercode;
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setCompanyname(_response.Text) 
    }

    async function handleEmployerCodeChange(e) {
        GetCompanyName(e);
    }


    async function GetSex() {

        var url = "/DropDown/GetMaster_name?mastername=Sex";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setSexList(_response)
    }
    async function GetExperience() {

        var url = "/DropDown/GetMaster_name?mastername=Experience";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setExperienceList(_response)
    }
    async function GetSalary() {

        var url = "/DropDown/GetMaster_name?mastername=Salary";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setSalaryList(_response)
    }


    async function IUDOperation() {
        var Jobtitle = jobtitle;
        var Companyname = companyname;
        var Experiencedetails = experiencedetails;
        var Vacancis = vacancies;
        var Disclose = disclosefromdate;
        var Location = locate
        var Skills = skills;
        var Jobdescription = jobdescription;
        var Industrytype = industrytype;
        var Functionalarea = functionalarea;
        var Role = role;
        var Rolecategory = rolecategory;
        var Employmenttype = employmenttype;
        var Education = education;
        var Country = country;
        var State = state;
        var District = district;
        var City = city;
        var Salary = salary;
        var Disclosetodate = disclosetodate;
        var Employercode = employercode;

        if (Jobtitle == "") {
            toast.error('Please enter a Job Title')
            return false;
        }
        if (Companyname == "") {
            toast.error('Please enter Company Name')
            return false;
        }
        if (Experiencedetails == "") {
            toast.error('Please Choose Experience Details')
            return false;
        }
        if (Vacancis == "") {
            toast.error('Please enter Vacancies')
            return false;
        }
        if (Disclose == "") {
            toast.error('Please enter Disclose')
            return false;
        }
        if (Location == "") {
            toast.error('Please enter Location')
            return false;
        }
        if (Skills == "") {
            toast.error('Please enter Skills')
            return false;
        }

        if (Disclosetodate == "") {
            toast.error('Please Choose To date')
            return false;
        }
        if (Industrytype == "") {
            toast.error('Please enter Industrytype')
            return false;
        }
        if (Functionalarea == "") {
            toast.error('Please enter Functionalarea')
            return false;
        }
        if (Role == "") {
            toast.error('Please enter Role')
            return false;
        }
        if (Rolecategory == "") {
            toast.error('Please enter Rolecategory')
            return false;
        }
        if (Employmenttype == "") {
            toast.error('Please enter Employmenttype')
            return false;
        }
        if (Education == "") {
            toast.error('Please enter Education')
            return false;
        }
        if (Salary == "") {
            toast.error('Please Choose Salary')
            return false;
        }

        var Param = {
            "mstjoblistid": ID,
            "joblistcode": Employercode,
            "jobtitle": Jobtitle,
            "companyname": Companyname,
            "experiencedetails": Experiencedetails,
            "vacancy": Vacancis,
            "disclosefromdate": Disclose,
            "location": Location,
            "skils": Skills,
            "jobdescription": Jobdescription,
            "industrytype": Industrytype,
            "functionalarea": Functionalarea,
            "role": Role,
            "rolecategory": Rolecategory,
            "employmenttype": Employmenttype,
            "education": Education,
            "active": "string",
            "searchField": Salary,
            "createdBy": 0,
            "createdDate": "2022-11-17T05:12:20.480Z",
            "flag": Flag, 
            "disclosetodate": Disclosetodate,
            "brief":brief
        };
        var url = "/Postjobs/IUDPostJobs";
        let _response = await Api.IUD(Param, url);
        showAlert(_response);
        setTimeout(() => {
            navigate('/Postjobstable')
        }, 2000)

    }
    const showAlert = (_response) => {
        Swal.fire({
            title: "Success",
            text: _response,
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    async function GetQualification() {

        var url = "/DropDown/GetQualification";
        let _response = await Api.BindDropdown(url)
        let Arry_Title1 = [];
        setQualification(_response)
    }
    async function Get(id) {
        var category = sessionStorage.getItem("AccessType");
        var Param = { mstjoblistid: id, flag: category }
        var url = "/Postjobs/BindPostJobs";
        let _response = await Api.BindGrid(Param, url)
        setJobtitle(_response[0].Jobtitle)
        setCompanyname(_response[0].Companyname)
        setExperiencedetails(_response[0].Experiencedetails)
        setVacancies(_response[0].Vacancy)
        setDisclosefromdate(_response[0].Disclosefromdate)
        setLocation(_response[0].Location)
        setSkills(_response[0].Skils)
        setJobdescription(_response[0].Jobdescription)
        setIndustrytype(_response[0].Industrytype)
        setFunctionalarea(_response[0].Functionalarea)
        setRole(_response[0].Role)
        setRolecategory(_response[0].Rolecategory)
        setEmploymenttype(_response[0].Employmenttype)
        setEducation(_response[0].Education)
        setCountry(_response[0].Country)
        setState(_response[0].State)
        setDistrict(_response[0].District)
        setCity(_response[0].City)
        setDisclosetodate(_response[0].Disclosetodate)
        setSalary(_response[0].SearchField)

    }

    const today = new Date().toISOString().split("T")[0];

    const handleDiscloseFromDateChange = (e) => {
        const newFromDate = e.target.value;
        setDisclosefromdate(newFromDate);

        const newToDate = new Date(newFromDate);
        newToDate.setDate(newToDate.getDate() + 90);
        setDisclosetodate(newToDate.toISOString().split("T")[0]);
    };


    return (
        <div>
            <Toaster toastOptions={{ position: "top-right" }} />
            {sessionStorage.getItem("AccessType") == 'Employer' ?
                <EmployerBase /> : null}
            {sessionStorage.getItem("AccessType") == 'Employee' ||
                sessionStorage.getItem("AccessType") == 'Agent' ||
                sessionStorage.getItem("AccessType") == 'User'
                ?
                <Base /> : null}
            <div id="main" class="main">
                <div class="pagetitle">

                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/Employerdashboard">Home</Link></li>
                        </ol>
                    </nav>
                </div>

                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Post Job Form</h5>
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="jobtitle">Job Title</label>
                                <input type="text" class="form-control" placeholder="Job Title" disabled={Flag == "D" || Flag == "null" ? true : false} value={jobtitle} onChange={(e) => setJobtitle(e.target.value)} />
                            </div>

                            {sessionStorage.getItem("AccessType") == "Employer" ?
                                <div class="col-md-6">
                                    <label for="employercode">Employer Code</label>
                                    <input type="text" class="form-control" disabled="true" placeholder="Employer code" value={employercode} onChange={(e) => setEmployercode(e.target.value)} />
                                </div>
                                :
                                <div class="col-md-6">
                                    <label for="employercode">Employer Code</label>
                                    <select id="inputState" class="form-select" value={employercode} onChange={(e) => {
                                        setEmployercode(e.target.value);
                                        handleEmployerCodeChange(e.target.value);
                                    }}>
                                        <option selected >Employer Code</option>
                                        {employercodelist &&
                                            employercodelist.map((i, index) => (
                                                <option value={i.Text}>{i.Text}</option>
                                            ))}
                                    </select>
                                </div>

                            }

                            {sessionStorage.getItem("AccessType") == "Employer" ?
                                <div class="col-md-6">
                                    <label for="companyname">Company Name</label>
                                    <input type="text" class="form-control" disabled="true" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />
                                </div>
                                :
                                <div class="col-md-6">
                                    <label for="employercode">Company Name</label>
                                    <input type="text" class="form-control" disabled="true" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} />
                                    
                                </div>
                            }
                            <div class="col-md-6">
                                <label for="experiencedetails">Experience Details</label>
                                <select id="inputState" class="form-select" value={experiencedetails}   onChange={(e) => setExperiencedetails(e.target.value)}  >
                                    <option selected>Experience Details</option>
                                    {experienceList &&
                                        experienceList.map((i, index) => (
                                            <option value={i.Text1}>{i.Text1}</option>
                                        ))}
                                </select>
                            </div>

                            <div class="col-md-6">
                                <label for="vacancies">Vacancies</label>
                                <input type="text" class="form-control" placeholder="Vacancies" disabled={Flag == "D" || Flag == "null" ? true : false} value={vacancies} onChange={(e) => setVacancies(e.target.value)} />
                            </div>
                            <div class="col-md-3">
                                <label for="disclose">Disclose From Date</label>
                                <input
                                    type="date"
                                    min={today}
                                    className="form-control"
                                    placeholder="From"
                                    disabled={Flag === "D" || Flag === "null"}
                                    value={disclosefromdate}
                                    onChange={handleDiscloseFromDateChange}
                                />
                            </div>

                            <div class="col-md-3">
                                <label for="disclose">Disclose To Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="To"
                                    disabled={Flag === "D" || Flag === "null"}
                                    min={disclosefromdate}
                                    max={disclosefromdate ? new Date(new Date(disclosefromdate).setDate(new Date(disclosefromdate).getDate() + 90)).toISOString().split("T")[0] : ""}
                                    value={disclosetodate}
                                    onChange={(e) => setDisclosetodate(e.target.value)}
                                />
                            </div>
                            <div class="col-md-6">
                                <label for="location">Location</label>
                                <input type="text" class="form-control" placeholder="Location" disabled={Flag == "D" || Flag == "null" ? true : false} value={locate} onChange={(e) => setLocation(e.target.value)} />
                            </div>

                            <div class="col-md-6">
                                <label for="jobdescription">Job Description</label>
                               
                                <input type="text" class="form-control" placeholder="Job Description" disabled={Flag == "D" || Flag == "null" ? true : false} value={jobdescription} onChange={(e) => setJobdescription(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="skills">Skills</label>
                                <input type="text" class="form-control" placeholder="Skills" disabled={Flag == "D" || Flag == "null" ? true : false} value={skills} onChange={(e) => setSkills(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="industrytype">Industry Type</label>
                                <input type="text" class="form-control" placeholder="Industry Type" disabled={Flag == "D" || Flag == "null" ? true : false} value={industrytype} onChange={(e) => setIndustrytype(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="functionalarea">Functional Area</label>
                                <input type="text" class="form-control" placeholder="Functional Area" disabled={Flag == "D" || Flag == "null" ? true : false} value={functionalarea} onChange={(e) => setFunctionalarea(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="role">Role</label>
                                <input type="text" class="form-control" placeholder="Role" disabled={Flag == "D" || Flag == "null" ? true : false} value={role} onChange={(e) => setRole(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="rolecategory">Role Category</label>
                                <input type="text" class="form-control" placeholder="Role Category" disabled={Flag == "D" || Flag == "null" ? true : false} value={rolecategory} onChange={(e) => setRolecategory(e.target.value)} />
                            </div>
                            <div class="col-md-6">
                                <label for="employmenttype">Employment Type</label>
                                <select id="inputState" class="form-select" disabled={Flag == "D" || Flag == "null" ? true : false} value={employmenttype} onChange={(e) => setEmploymenttype(e.target.value)}>

                                    <option selected>Employment Type</option>
                                    {emptype &&
                                        emptype.map((i, index) => (
                                            <option value={i.Text}>{i.Text}</option>
                                        ))}

                                </select>
                            </div> 

                            <div class="col-md-6">
                                <label for="qualification"> Qualifcation</label>
                                <select id="inputState" class="form-select" value={education} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setEducation(e.target.value)}  >
                                    <option selected>Qualification</option>
                                    {qualification &&
                                        qualification.map((i, index) => (
                                            <option value={i.Text}>{i.Text}</option>
                                        ))}
                                </select>
                            </div> 

                            <div class="col-md-6">
                                <label for="qualification"> Salary</label>
                                <select id="inputState" class="form-select" value={salary} disabled={Flag == "D" || Flag == "null" ? true : false} onChange={(e) => setSalary(e.target.value)}  >
                                    <option selected>Salary</option>
                                    {salaryList &&
                                        salaryList.map((i, index) => (
                                            <option value={i.Text1}>{i.Text1}</option>
                                        ))}
                                </select>
                            </div>


                            <div class="col-md-6">
                                <label for="role">Give the Brief of the Job Details</label>
                                <textarea class="form-control"  value={brief}   onChange={(e) => setBrief(e.target.value)} ></textarea>
                            </div>

                            <div class="text-center">
                                <button type="button" class="btn btn-primary" onClick={IUDOperation}>Submit</button>
                                <button type="reset" class="btn btn-info"><Link to="/Postjobstable">Back</Link></button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

